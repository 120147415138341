export const EXCLUDED_URLS = [
  "amazon.com",
  "amzn.to",
  "bit.ly",
  "click.linksynergy.com",
  "del.icio.us",
  "digg.com",
  "ebay.com",
  "ebay.to",
  "etsy.com",
  "google.com",
  "facebook.com",
  "fave.co",
  "flic.kr",
  "href.li",
  "instagram.com",
  "linkedin.com",
  "netflix.com",
  "onlyfans.com",
  "pinterest.com",
  "rover.ebay.com",
  "rstyle.me",
  "shop-edits.co",  // Narrativ
  "shop-links.co", // Narrativ
  "soundcloud.com",
  "t.co",
  "t.umblr.com",
  "translate.google.com",
  "tumblr.com",
  "twitter.com",
  "wa.me", // Whatsapp
  "www.amazon.com", // regex?
  "www.amazon.com.au",
  "www.awin1.com",
  "www.etsy.com",
  "www.google.com",
  "www.facebook.com",
  "www.imdb.com",
  "www.instagram.com",
  "www.linkedin.com",
  "www.pinterest.com",
  "www.reddit.com",
  "www.stumbleupon.com",
  "www.tumblr.com", // Can we do a regex?
  "www.twitter.com",
  "www.youtube.com",
  "youtube.com",
  // *.apple.com
  // *.google.com
  // *.photobucket.com
];
export const DISALLOWED_EXTENSIONS = ["gif", "jpg", "jpeg", "png", "svg", "pdf", "doc", "docx"];
