import initialise from "./initialise";

// The general architecture of the publisher autolinker is as follows:
//
//  - Find all links on the page that seem like they could be to network merchants. We'll filter out
//    stuff like images / javascript / internal links
//  - Send the links to Kutoku
//  - Kutoku determines whether these links are for partner merchants and returns an updated link
//    if they are
//  - Replace all URLs with our affiliated links

function ready(fn) {

  window.KTK = {
    publisherId: "{{{ID}}}",
    publisherSnippetUrl: "https://{{{SNIPPET_URL}}}",
  };

  // document.attachEvent is IE specific and will be removed in IE 11
  if (
    document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading"
  ) {
    fn();
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
}

// Execute our script when the document is ready
ready(initialise);
