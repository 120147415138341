/**
 * @summary Determines whether the clicked URL is excluded (e.g. it's for a paid article)
 *          and thus we should not convert links
 * @param   {Element} anchor - An anchor DOM element
 * @returns {Boolean} Whether the url was excluded or not
 */
function isExcludedAnchor(anchor) {
  const relAttr = anchor.getAttribute("rel");
  return relAttr && relAttr.includes("nokutoku");
}

export default isExcludedAnchor;
