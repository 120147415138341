import fetch from 'unfetch'

// Send the array of links to our API for conversion to affiliate links
//
// We're sending data as a form submission because this allows us to avoid
// sending an OPTIONS pre-flight request - https://stackoverflow.com/a/29954326

async function sendLinks(anchorTags) {
  const formData = new FormData();
  formData.append("publisher[id]", KTK.publisherId);
  formData.append("publisher[url]", window.location.href);
  formData.append("publisher[pageTitle]", window.document.title);
  Object.entries(anchorTags).forEach(([linkId, url]) => formData.append(`links[${linkId}]`, url));

  const response = await fetch(
    `${KTK.publisherSnippetUrl}/convert-links`, {
      method: "POST",
      body: formData,
    }
  );

  if (response.ok) {
    const data = await response.json();
    return { success: true, data };
  }

  return { success: false, error: "Unknown..." };
}

export default sendLinks;
