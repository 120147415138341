/**
 * @summary Parses a URL into its constituent parts
 * @param   {String} url - The url to parse
 * @returns {Object} The parsed URL containing the following keys:
 *                   href, protocol, host, hostname, origin, port, pathname, search, hash
 */
function linkParser(url) {
  var parser = document.createElement('a');
  parser.href = url;
  return parser;
}

export default linkParser;
