import log from './log';
import sendLinks from './send-links';

// anchorTags is an ARRAY of tags, not an HTMLCollection. This is because we needed to filter the
// collection and that's only practically possible by converting to an array.

async function convertLinks(anchorTags, action = 'on page load') {
  log(`=== Converting links ${action} ===`);

  const linkCount = Object.keys(anchorTags).length;

  if (linkCount < 1) {
    log('No links found');
    return;
  } else {
    log(`Found ${linkCount} link(s) to convert`);
  }

  const convertedLinks = await sendLinks(anchorTags);

  if (!convertedLinks.success) {
    // TODO: Log to Sentry
    log('Failed to convert links');
    return null;
  }

  const shouldAddLinkQualification = convertedLinks.data.options.useLinkQualification

  // Update hrefs for any links
  // Comment this our to run the autolinker without actually updating any links
  Object.entries(convertedLinks.data.links).forEach((convertedLink) => {
    const anchorTag = anchorTags[convertedLink[0]];
    log(`Converted: ${anchorTag} => ${convertedLink[1]}`);
    anchorTag.setAttribute('href', convertedLink[1]);

    // Disable Skimlinks and Viglink for Kutoku links
    // https://developers.skimlinks.com/skimlinks-script.html
    // https://viglink-developer-center.readme.io/docs/configuration-with-html
    anchorTag.classList.add('noskim', 'norewrite');

    // Clean up by removing the data-kutoku attribute
    // anchorTag.removeAttribute('data-kutoku');

    // https://developers.google.com/search/docs/advanced/guidelines/qualify-outbound-links
    // If the publisher so desires, add rel='sponsored' to Kutoku
    if (shouldAddLinkQualification) {
      const existingRel = anchorTag.getAttribute('rel'); // null if non-existent

      // Skip this link if it already has rel="sponsored"
      if (existingRel && existingRel.includes('sponsored')) return;

      // Add / append the rel
      const newRel = existingRel ? `${existingRel} sponsored` : 'sponsored';
      anchorTag.setAttribute('rel', newRel); 
    }
  });
}

export default convertLinks;
