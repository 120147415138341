/**
 * @summary Determines whether the clicked URL has already been processed by
 *          Narrativ and so should not be converted 
 * @param   {Element} anchor - An anchor DOM element
 * @returns {Boolean} Whether the url was excluded or not
 */
function isNarrativAnchor(anchor) {
  return !!anchor.dataset.bamxLink
}

export default isNarrativAnchor;
