import linkParser from "./link-parser";
import isExcludedAnchor from "./is-excluded-anchor";
import isNarrativAnchor from "./is-narrativ-anchor";
import log from "./log";
import { DISALLOWED_EXTENSIONS, EXCLUDED_URLS } from "./config";

/**
 * @summary Determines whether a given URL should be sent
 * @param   {String} url - The url to be tested
 * @returns {Boolean} - Whether to send the URL or not
 */
function shouldSendLink(element) {
  const url = element.href;
  const parsedLink = linkParser(url);

  // Don't send when the URL is `javascript:void(null);`
  if (url.startsWith("javascript:")) {
    log("Excluded: javascript is disallowed");
    return false;
  }

  // Exit early if the link has an undesirable (e.g. image) extension.
  const extension = parsedLink.pathname.split(".").slice(-1)[0];
  if (DISALLOWED_EXTENSIONS.includes(extension)) {
    log(`Excluded: .${extension} is disallowed`);
    return false;
  }

  // Exit early if the protocol is not http(s)
  if (!parsedLink.protocol.startsWith("http")) {
    log(`Excluded: ${parsedLink.protocol} is disallowed`);
    return false;
  }

  // Don't send when the anchor contains rel=nokutoku
  if (isExcludedAnchor(element)) {
    log("Excluded: Link has rel=nokutoku attribute");
    return false;
  }

  // Don't send if the link has been processed by Narrativ
  if (isNarrativAnchor(element)) {
    log("Excluded: Link has already been processed by Narrativ");
    return false;
  }

  // Don't send when the query string contains ktk= (V2 manual link)
  if (parsedLink.search.includes("ktk=")) {
    log("Excluded: Found ktk= in the query string");
    return false;
  }

  // Don't send excluded URLs
  if (EXCLUDED_URLS.includes(parsedLink.host)) {
    log("Excluded: Found an excluded URL");
    return false;
  }

  // Don't send internal URLs
  if (window.location.host === parsedLink.host) {
    log(`Excluded (internal link): ${element}`);
    return false;
  }

  return true;
}

export default shouldSendLink;
