import shouldSendLink from "./should-send-link";
import addDataIds from "./add-data-ids";
import log from "./log";

function findAnchorTags() {
  // https://www.w3.org/TR/REC-DOM-Level-1/level-one-core.html#method-getElementsByTagName
  // Returns a NodeList of all the Elements with a given tag name in the order in which they would
  // be encountered in a preorder traversal of the Document tree.
  let anchorTags = document.getElementsByTagName("a");

  // - Get urls
  // - Filter out 'undesirable' links

  anchorTags = Array.prototype.slice.call(anchorTags);
  anchorTags = anchorTags.filter((tag, index) => {
    log(`=== Processing link ${index} : ${tag.href} ===`);
    return shouldSendLink(tag);
  });

  // Sometimes (e.g. when a site is viewed in a Google Translate iframe)
  // all the links can be empty strings. We should handle this case.
  // https://translate.google.com/translate?hl=fr&sl=en&tl=fr&u=https%3A%2F%2Fkutoku-publisher.com%2Fstaging.html
  anchorTags = anchorTags.filter(anchorTag => !!anchorTag.href);

  return addDataIds(anchorTags);
}

export default findAnchorTags;
