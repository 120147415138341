var isDevelopment = (function () {
  var parser = document.createElement('a');
  parser.href = window.location;
  const result = parser.host === 'kutoku-publisher.test';
  if (result) console.warn('[KTK] Development mode is enabled')
  return result
})();

var isDebug = (function () {
  var parser = document.createElement('a');
  parser.href = window.location;
  const result = parser.search.indexOf('kutoku-debug') !== -1;
  if (result) console.warn('[KTK] Debug mode is enabled')
  return result
})();

function log(obj) {
  if (isDevelopment || isDebug) console.log('[KTK]', obj);
}

export default log;
