import generateUid from './generate-uid';

function addDataIds(anchorTags) {
  const data = {};

  // Add a unique ID to the URL so we can replace them accurately
  // Note that this cannot be an integer index because this code is run when new elements are
  // added to the DOM. There will already be links with data-kutoku attributes.
  anchorTags.forEach((anchorTag) => {
    const uid = generateUid();
    anchorTag.setAttribute('data-kutoku', uid);
    data[uid] = anchorTag;
  });

  return data;
}

export default addDataIds;
